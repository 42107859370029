import { ITableGame } from 'src/lib/interfaces';
import { getAcronym } from './teamUtils';

export const getScoreColor = (obj: ITableGame): string => {
  const ec = getAcronym(obj.equip_casa_original);
  const ev = getAcronym(obj.equip_visitant_original);

  const gols_casa =
    typeof obj.gols_casa === 'number' ? obj.gols_casa : parseInt(obj.gols_casa);

  const gols_visitant =
    typeof obj.gols_visitant === 'number'
      ? obj.gols_visitant
      : parseInt(obj.gols_visitant);

  if (
    (ec !== undefined && gols_casa > gols_visitant) ||
    (ev !== undefined && gols_visitant > gols_casa)
  )
    return '#037603da';
  if (
    (ec === undefined && gols_casa > gols_visitant) ||
    (ev === undefined && gols_visitant > gols_casa)
  )
    return '#eb0000dc';

  return '#000';
};
