import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { IPasswordInputError } from 'src/lib/interfaces';

const style = {
  width: '80%',
  height: '100%',
  label: {
    fontSize: 12,
  },
  backgroundColor: 'transparent',

  '& .MuiOutlinedInput-root': {
    backgroundColor: 'transparent',
    '& >fieldset': {
      backgroundColor: 'transparent',
      border: 'none',
    },
    '&.Mui-focused': {
      color: '#727272c0', // focus
    },
    '&.Mui-focused label': {
      color: '#a9a9a9c0', // focus
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#a9a9a9c0',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#727272c0',
  },

  '& .MuiOutlinedInput-root:hover': {
    '& >fieldset': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      border: 'none', // focus
    },
  },
  '&: hover': {
    boxShadow: 0,
    label: {
      fontSize: 12,
    },
  },
  '&.Mui-focused': {
    label: {
      fontSize: 18,
      color: 'blue',
    },
  },
};

interface InputProps {
  label: string;
  name: string;
  handlePassword: (arg0: string) => void;
  error?: IPasswordInputError;
}

const Input: React.FC<InputProps> = ({
  label,
  name,
  handlePassword,
  error,
}) => {
  const [passwordValue, setPasswordValue] = useState<string>('');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordValue(e.target.value);
  };

  const handleSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handlePassword(passwordValue);
    }
  };
  return (
    <TextField
      label={label}
      name={name}
      variant='outlined'
      type='password'
      size='small'
      fullWidth
      autoFocus
      value={passwordValue}
      onChange={handleChange}
      onKeyDown={handleSubmit}
      error={error && Object.keys(error).length !== 0 ? true : false}
      helperText={error ? error?.message : ''}
      sx={{ ...style }}
    />
  );
};

export default Input;
