import { getFullName, getTeamServerName, isSameTeam } from './teamUtils';
import { createDate } from './dateUtils';
import { IFormQuery, IGame, ISelectObj, ITableGame } from 'src/lib/interfaces';
import { teamsMap } from './teamsMap';

export const placeholderToUndefined = (data: IFormQuery) => {
  Object.entries(data).forEach((pair) => {
    if (pair[1] === 'placeholder' || pair[1] === 'all') {
      data[pair[0] as keyof IFormQuery] = undefined;
    }
  });
};

export const getLabelandValue = (temp: number[] | string[]): ISelectObj[] => {
  const bones = temp.map((tempItem) => {
    if (typeof tempItem === 'number') {
      const str = `${tempItem} - ${tempItem + 1}`;
      return { label: str, value: tempItem };
    }
    return { label: tempItem, value: tempItem };
  });
  return bones;
};

export const getTableData = (data: IGame[]): ITableGame[] => {
  const new_data = data.map((partit: IGame) => {
    const {
      _id,
      num,
      data,
      fase,
      categoria,
      temporada,
      gols_visitant,
      gols_casa,
      equip_casa,
      equip_visitant,
    } = partit;
    const new_data =
      data != null
        ? new Date(data).toLocaleString('ca-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
        : data;
    const temporada_str = `${temporada}`;
    let extra = (parseInt(temporada_str.substring(2, 4)) + 1).toString();
    extra = ('0' + extra).slice(-2);
    const nova_temporada = `${temporada} - ${extra}`;
    const resultat = `${gols_casa} - ${gols_visitant}`;
    const nou_equip_casa = getFullName(equip_casa);
    const nou_equip_visitant = getFullName(equip_visitant);
    const nou_partit = {
      _id,
      num,
      fase,
      categoria,
      data: new_data,
      temporada: nova_temporada,
      resultat,
      equip_casa_original: equip_casa,
      equip_visitant_original: equip_visitant,
      equip_casa: nou_equip_casa,
      equip_visitant: nou_equip_visitant,
      gols_casa,
      gols_visitant,
    };
    return nou_partit;
  });
  return new_data;
};

export const getServerData = (game: ITableGame): IGame => {
  const {
    _id,
    num,
    temporada,
    categoria,
    fase,
    resultat,
    equip_casa,
    equip_visitant,
    equip_casa_original,
    equip_visitant_original,
    data,
  } = game;
  const new_temporada = parseInt(temporada.substring(0, 4));

  const resultatTrimmed = resultat.replace(/\s/g, '');
  const indexResultat = resultatTrimmed.indexOf('-');
  const gols_casa = parseInt(resultatTrimmed.substring(0, indexResultat));
  const gols_visitant = parseInt(resultatTrimmed.substring(indexResultat + 1));

  const new_data = data != null ? createDate(data.trim()) : data;

  const new_fase = fase.trim();

  const new_categoria = categoria.trim();

  let new_equip_casa = getTeamServerName(equip_casa.trim());
  let new_equip_visitant = getTeamServerName(equip_visitant.trim());

  new_equip_casa =
    (new_equip_casa !== equip_casa_original &&
      !isSameTeam(new_equip_casa, equip_casa_original)) ||
    equip_casa_original === undefined
      ? new_equip_casa
      : equip_casa_original;

  new_equip_visitant =
    (new_equip_visitant !== equip_visitant_original &&
      !isSameTeam(new_equip_visitant, equip_visitant_original)) ||
    equip_visitant_original === undefined
      ? new_equip_visitant
      : equip_visitant_original;

  if (!teamsMap.has(new_equip_casa) && !teamsMap.has(new_equip_visitant)) {
    throw new Error('No hi ha cap equip de Mataró');
  }
  if (teamsMap.has(new_equip_casa) && teamsMap.has(new_equip_visitant)) {
    throw new Error('No pots considerar tots dos equips de Mataró');
  }
  const new_game = {
    _id,
    num,
    temporada: new_temporada,
    data: new_data,
    categoria: new_categoria,
    fase: new_fase,
    equip_casa: new_equip_casa,
    equip_visitant: new_equip_visitant,
    gols_casa,
    gols_visitant,
  };
  return new_game;
};
