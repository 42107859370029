import React, { useRef, useCallback, useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import Input from 'src/components/shared/Input';
import { IPasswordInputError } from 'src/lib/interfaces';
import { authorize } from 'src/api/http-common';
import { useData, useDataUpdate } from 'src/contexts/AdminContext';
import { Background, Wrapper, InputWrapper } from './AdminModalStyles';

const AdminModal = () => {
  const [error, setError] = useState<IPasswordInputError>({});
  const { showAdminModal } = useData();
  const { setAdmin, setShowAdminModal } = useDataUpdate();
  const modalRef = useRef<HTMLDivElement>(null);

  const closeModal = (event: { target: unknown }) => {
    if (modalRef.current === event.target) {
      setError({});
      setShowAdminModal(false);
    }
  };

  const keyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && showAdminModal) {
        setError({});
        setShowAdminModal(false);
      }
    },
    [setShowAdminModal, showAdminModal]
  );

  const handlePassword = (password: string): void => {
    void (async () => {
      try {
        const authorized = await authorize(password);
        if (authorized.data) {
          setAdmin(true);
          setShowAdminModal(false);
        } else {
          setError({ message: 'Password Incorrecte' });
        }
      } catch (err) {
        setShowAdminModal(false);
        if (err instanceof Error) alert(err.message);
      }
    })();
  };

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  return ReactDom.createPortal(
    <>
      {showAdminModal && (
        <Background ref={modalRef} onClick={closeModal}>
          <Wrapper>
            <>
              <InputWrapper>
                <Input
                  data-testid='passwordInput'
                  label='Administrator Password'
                  name='adminPassword'
                  handlePassword={handlePassword}
                  error={error}
                />
              </InputWrapper>
            </>
          </Wrapper>
        </Background>
      )}
    </>,
    (document.getElementById('portal') as HTMLElement) ||
      document.createElement('div') // for testing  purposes
  );
};

export default AdminModal;
