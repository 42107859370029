import React, { useState, useContext, FC } from 'react';
import { IFormQuery, SportType } from 'src/lib/interfaces';

export type ContextProps = {
  admin: boolean;
  queryParams: IFormQuery;
  showResults: boolean;
  sportType: SportType;
  showSecondSeason: boolean;
  showAdminModal: boolean;
};

export type ContextUpdateProps = {
  setAdmin: React.Dispatch<React.SetStateAction<boolean>>;
  setQueryParams: React.Dispatch<React.SetStateAction<IFormQuery>>;
  setShowResults: React.Dispatch<React.SetStateAction<boolean>>;
  setSportType: React.Dispatch<React.SetStateAction<SportType>>;
  setShowSecondSeason: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAdminModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DataContext = React.createContext<ContextProps>(
  {} as ContextProps
);

export const DataUpdateContext = React.createContext<ContextUpdateProps>(
  {} as ContextUpdateProps
);

export function useData() {
  return useContext(DataContext);
}

export function useDataUpdate() {
  return useContext(DataUpdateContext);
}

export const DataProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [admin, setAdmin] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<IFormQuery>({});
  const [showResults, setShowResults] = useState<boolean>(false);
  const [sportType, setSportType] = useState<SportType>('');
  const [showSecondSeason, setShowSecondSeason] = useState<boolean>(false);
  const [showAdminModal, setShowAdminModal] = useState<boolean>(false);

  return (
    <DataContext.Provider
      value={{
        admin,
        queryParams,
        showResults,
        sportType,
        showSecondSeason,
        showAdminModal,
      }}
    >
      <DataUpdateContext.Provider
        value={{
          setQueryParams,
          setShowResults,
          setSportType,
          setShowSecondSeason,
          setShowAdminModal,
          setAdmin,
        }}
      >
        {children}
      </DataUpdateContext.Provider>
    </DataContext.Provider>
  );
};
