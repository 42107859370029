import React, { useEffect, useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';
import { IGameData, ITableGame } from 'src/lib/interfaces';
import { getGamesResult } from '../../api/http-common';
import { getTableData } from '../../utils/dataUtils';
import { CustomTable as Table } from './Table';
import { useData } from '../../contexts/AdminContext';

const Wrapper = styled.div`
  width: 100%;
`;

export const Results: React.FC = () => {
  const { queryParams, sportType } = useData();
  const [formattedData, setFormattedData] = useState<ITableGame[]>([]);
  const {
    isLoading,
    isError,
    isSuccess,
    error,
    data,
  }: UseQueryResult<IGameData, Error> = useQuery<IGameData, Error>(
    ['results', queryParams, sportType],
    () => getGamesResult(queryParams, sportType)
  );

  useEffect(() => {
    if (data?.data.data) {
      setFormattedData(getTableData(data.data.data));
    }
  }, [data?.data]);

  return (
    <Wrapper data-test-id='results-table'>
      {isLoading && <ClipLoader />}
      {isError && <h1>{error?.message}</h1>}
      {isSuccess && data && <Table data={formattedData} />}
    </Wrapper>
  );
};
