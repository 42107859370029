import styled from 'styled-components';
import { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';

export const switchRoot = styled.span`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 12px;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: #e5e5e5;
    border-radius: 5px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 18px;
    height: 18px;
    top: -3px;
    left: 0px;
    border-radius: 18px;
    background-color: #ccc;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: #a9a9a9;
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 25px;
      top: -3px;
      background-color: #a9a9a9;
    }

    .${switchUnstyledClasses.track} {
      background-color: #ddd;
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;
