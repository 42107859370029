import { AxiosResponse } from 'axios';
import {
  IGame,
  IGameData,
  ISearchData,
  IFormQuery,
  SportType,
} from 'src/lib/interfaces';
import http from './axiosInstance';

export const getSearchData = async (
  sportType: SportType
): Promise<ISearchData> => {
  try {
    const response: AxiosResponse = await http.get(`/sport/${sportType}`);
    return response;
  } catch (err) {
    const error =
      err instanceof Error ? err.message : 'There has been an error';
    throw Error(error);
  }
};

export const getGamesResult = async (
  data: IFormQuery,
  sportType: SportType
): Promise<IGameData> => {
  try {
    const temporada = data.temporada !== undefined ? data.temporada : 'none';
    const segona_temporada =
      data.segona_temporada !== undefined ? data.segona_temporada : 'none';
    const categoria = data.categoria !== undefined ? data.categoria : 'none';
    const equip_rival =
      data.equip_rival !== undefined ? data.equip_rival : 'none';
    const equip_mataro =
      data.equip_mataro !== undefined ? data.equip_mataro : 'none';
    const response: AxiosResponse = await http.get(
      `result/${sportType}/${temporada}/${segona_temporada}/${categoria}/${equip_rival}/${equip_mataro}`
    );
    return response;
  } catch (err) {
    const error =
      err instanceof Error ? err.message : 'There has been an error';
    throw Error(error);
  }
};

interface removeGameVariables {
  id: string;
  sportType: SportType;
}

interface updateCreateGameVariables {
  game: IGame;
  sportType: SportType;
}

export const removeGame = async ({
  id,
  sportType,
}: removeGameVariables): Promise<IGameData> => {
  try {
    const response: AxiosResponse = await http.delete(
      `/delete/${sportType}/${id}`
    );
    return response;
  } catch (err) {
    const error =
      err instanceof Error
        ? `${err.name} - No s'ha pogut eliminar l'entrada`
        : 'There has been an error';
    throw new Error(error);
  }
};

export const updateGame = async ({
  game,
  sportType,
}: updateCreateGameVariables): Promise<IGameData> => {
  try {
    const response: AxiosResponse = await http.put('/edit', {
      game,
      sportType,
    });
    return response;
  } catch (err) {
    const error =
      err instanceof Error
        ? `${err.name} - No s'ha pogut actualitzar l'entrada`
        : 'There has been an error';
    throw new Error(error);
  }
};

export const createGame = async ({
  game,
  sportType,
}: updateCreateGameVariables): Promise<IGameData> => {
  try {
    const response: AxiosResponse = await http.post('/new', {
      game,
      sportType,
    });
    return response;
  } catch (err) {
    const error =
      err instanceof Error
        ? `${err.name} - No s'ha pogut afegir l'entrada` //`${err.response.statusText} - ${err.response.data.message}`
        : 'There has been an error';
    throw new Error(error);
  }
};

interface IAuthorizationResp {
  data: boolean;
}

export const authorize = async (
  password: string
): Promise<IAuthorizationResp> => {
  try {
    const response: AxiosResponse = await http.get(`/authorize/${password}`);
    return response;
  } catch (err) {
    const error =
      err instanceof Error
        ? `${err.name} - No s'ha pogut confirmar l'usuari`
        : 'There has been an error';
    throw new Error(error);
  }
};
