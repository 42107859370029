import React from 'react';
import styled from 'styled-components';
import { TextField, MenuItem } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { ISelectObj, SportType } from 'src/lib/interfaces';

interface WrapperProps {
  width?: string;
}

const Wrapper = styled.div<WrapperProps>`
  width: ${({ width }) => width || '60%'};
`;

const selectStyles = {
  width: '100%',
  backgroundColor: '#f9f9f9',

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      color: 'black',
      backgroundColor: 'transparent',
      border: '1px solid black',
      borderColor: '#a9a9a9c0',
      boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.075)',
      borderRadius: 1,
    },

    '&:hover fieldset': {
      border: '2px solid black',
      borderColor: '#8e8e8ec0',
    },
    '&.Mui-focused fieldset': {
      border: '2px solid black',
      borderColor: '#8e8e8ec0',
    },
  },
  label: {
    color: '#727272c0',
    //padding: 10,
  },
  '&:hover': {
    label: {
      //color: '#3a3a3ac0',
      fontWeight: 'bold',
      paddingRight: 20,
    },
  },

  '& label.Mui-focused': {
    color: '#727272c0',
    fontWeight: 'bold',
  },
};

type FieldType =
  | 'categoria'
  | 'temporada'
  | 'segona_temporada'
  | 'esport'
  | 'equip_rival'
  | 'equip_mataro';

interface Props {
  label: string;
  name: FieldType;
  options: ISelectObj[];
  handleField?: (value: SportType | number) => void;
  firstValue: boolean;
  placeholder: string;
  wrapperWidth: string;
}

export const CustomSelect: React.FC<Props> = ({
  label,
  name,
  options,
  handleField,
  firstValue,
  placeholder,
  wrapperWidth,
}) => {
  const { control } = useFormContext();

  const generateOptions = (options: ISelectObj[]) => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.icon !== undefined && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
              <img src={option.icon as string} alt='icona del esport' />
              {option.label}
            </div>
          )}
          {option.icon === undefined && <div>{option.label}</div>}
        </MenuItem>
      );
    });
  };

  const handleChange = (value: SportType | number) => {
    if (handleField) {
      handleField(value);
    }
  };

  return (
    <Wrapper width={wrapperWidth}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ...rest } }) => (
          <TextField
            {...rest}
            label={label}
            fullWidth
            variant={'outlined'}
            size={'small'}
            defaultValue='placeholder'
            onChange={(e) => {
              {
                handleChange(e.target.value as SportType | number);
              }
              onChange(e);
            }}
            select
            sx={{ ...selectStyles }}
          >
            <MenuItem disabled value='placeholder' sx={{ display: 'none' }}>
              <em>{placeholder}</em>
            </MenuItem>
            {firstValue && (
              <MenuItem value='all'>
                <em>Tots | Totes</em>
              </MenuItem>
            )}
            {generateOptions(options)}
          </TextField>
        )}
      />
    </Wrapper>
  );
};
