import styled from 'styled-components';

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 60px;
  right: 20px;
`;

export const InputWrapper = styled.div`
  width: 200px;
`;
