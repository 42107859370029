import React from 'react';
import styled from 'styled-components';
import { useData } from '../contexts/AdminContext';
import { Form } from 'src/components/Form/Form';
import { Results } from 'src/components/Results/Results';
import AdminModal from 'src/components/AdminModal/AdminModal';

const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding: 20px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  span {
    font-size: 14px;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const SearchPage: React.FC = () => {
  const { showResults } = useData();

  return (
    <MainWrapper>
      <Form />
      {showResults && <Results />}
      <BottomWrapper>
        <span>
          ©{' '}
          <a
            href={'http://www.ilurosport.cat'}
            target='_blank'
            style={{ textDecoration: 'none', color: '#000' }}
            rel='noreferrer'
          >
            ILUROSPORT.
          </a>{' '}
          2022. &nbsp;&nbsp; Disseny:{' '}
          <a
            href={'http://www.tactic.cat'}
            target='_blank'
            style={{ textDecoration: 'none', color: '#555' }}
            rel='noreferrer'
          >
            Tactic.cat &nbsp;&nbsp;
          </a>{' '}
        </span>
        <span>
          Developed by:{' '}
          <a
            href={'http://www.linkedin.com/in/sergi-ayora'}
            target='_blank'
            style={{ textDecoration: 'none', color: '#555' }}
            rel='noreferrer'
          >
            Sergi Ayora
          </a>
        </span>
      </BottomWrapper>
      <AdminModal data-testid='adminModal' />
    </MainWrapper>
  );
};
