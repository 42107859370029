import styled from 'styled-components';

export const Wrapper = styled.form`
  width: 100%;
  padding: 25px;
  border: 1px solid;
  background-color: #f2f2f2ae;
  border-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 780px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    gap: 20px;
  }
`;

export const Logo = styled.div`
  flex: 24%;
  img {
    width: 100px;
  }
  @media screen and (max-width: 780px) {
    padding-left: 0px;
    img {
      width: 60px;
    }
  }
`;

export const TitleSelectWrapper = styled.div`
  flex: 50%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  @media screen and (max-width: 1000px) {
    padding-left: 0px;
  }
`;

export const Title = styled.div`
  width: 33%;
  min-width: 250px;
  p {
    text-align: center;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 600;
  }
  @media screen and (max-width: 780px) {
    width: 100%;
  }
`;

export const EsportSelectWrapper = styled.div`
  width: 33%;
  min-width: 250px;
  @media screen and (max-width: 780px) {
    width: 100%;
  }
`;

export const AddWrapper = styled.div`
  flex: 5%;
  align-self: start;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: flex-start;
  span {
    padding-top: 3px;
    color: #727272bc;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }
  button {
    padding-left: 10px;
    cursor: pointer;
    border: none;
    background: transparent;
  }
  @media screen and (max-width: 780px) {
    display: none;
  }
`;
