/* eslint-disable no-async-promise-executor */
import React from 'react';
import MaterialTable from 'material-table';
import { useMutation, useQueryClient } from 'react-query';
import { useData } from 'src/contexts/AdminContext';
import tableIcons from 'src/utils/MaterialTableIcons';
import { removeGame, updateGame, createGame } from 'src/api/http-common';
import { getServerData } from 'src/utils/dataUtils';
import { getScoreColor } from 'src/utils/scoreColor';
import { ITableGame } from 'src/lib/interfaces';

interface Props {
  data: ITableGame[];
}

export const CustomTable: React.FC<Props> = ({ data }) => {
  const { admin, sportType } = useData();
  const queryClient = useQueryClient();
  const { mutateAsync: deleteAsync } = useMutation(removeGame);
  const { mutateAsync: updateAsync } = useMutation(updateGame);
  const { mutateAsync: createAsync } = useMutation(createGame);

  const adminColumns: any[] = [];

  const commonColumns = [
    {
      title: 'DATA',
      field: 'data',
      cellStyle: {
        width: '10%',
        //maxWidth: 200,
      },
      headerStyle: {
        width: '10%',
        // maxWidth: 200,
      },
    },
    {
      title: 'TEMPORADA',
      field: 'temporada',
      validate: (rowData: ITableGame) => {
        const regExp = /^(\d{4}|\d{4}\s-\s\d{2})$/;
        if (rowData.temporada === undefined || rowData.temporada === '') {
          return '*';
        } else if (!regExp.test(rowData.temporada)) {
          return 'Format invàlid';
        }
        return true;
      },
      cellStyle: {
        width: '10%',
        //maxWidth: 500,
      },
      headerStyle: {
        width: '10%',
        // maxWidth: 500,
      },
    },
    {
      title: 'COMPETICIÓ',
      field: 'categoria',
      validate: (rowData: ITableGame) => {
        if (rowData.categoria === undefined || rowData.categoria === '') {
          return '*';
        }
        return true;
      },
      cellStyle: {
        width: '20%',
        // maxWidth: 500,
      },
      headerStyle: {
        width: '20%',
        // maxWidth: 500,
      },
    },
    {
      title: 'FASE',
      field: 'fase',

      cellStyle: {
        width: '10%',
        // maxWidth: 500,
      },
      headerStyle: {
        width: '10%',
        // maxWidth: 500,
      },
      //lookup: { '': null },
      //emptyValue: () => <em>Fase Regular</em>,
      //align: 'right',
    },
    {
      title: 'LOCAL',
      field: 'equip_casa',
      validate: (rowData: ITableGame) => {
        if (rowData.equip_casa === undefined || rowData.equip_casa === '') {
          return '*';
        }
        return true;
      },
      cellStyle: {
        width: '20%',
        // maxWidth: 500,
      },
      headerStyle: {
        width: '20%',
        // maxWidth: 500,
      },
      render: (rowData: ITableGame) => (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: 14,
            color: getScoreColor(rowData),
          }}
        >
          {rowData.equip_casa.toUpperCase()}
        </div>
      ),
    },
    {
      title: 'RESULTAT',
      field: 'resultat',
      validate: (rowData: ITableGame) => {
        const regExp = /^\d+\s-\s\d+$/;
        if (rowData.resultat === undefined || rowData.resultat === '') {
          return '*';
        } else if (!regExp.test(rowData.resultat)) {
          return 'Format invàlid';
        }
        return true;
      },
      cellStyle: {
        width: '10%',
        //maxWidth: 500,
      },
      headerStyle: {
        width: '10%',
        // maxWidth: 500,
      },
      render: (rowData: ITableGame) => (
        <div
          style={{
            fontSize: 16,
            fontWeight: 'bold',
            color: getScoreColor(rowData),
          }}
        >
          {rowData.resultat}
        </div>
      ),
    },
    {
      title: 'VISITANT',
      field: 'equip_visitant',
      validate: (rowData: ITableGame) => {
        if (
          rowData.equip_visitant === undefined ||
          rowData.equip_visitant === ''
        ) {
          return '*';
        }
        return true;
      },
      cellStyle: {
        width: '20%',
        // maxWidth: 500,
      },
      headerStyle: {
        width: '20%',
        // maxWidth: 500,
      },
      render: (rowData: ITableGame) => (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: 14,
            color: getScoreColor(rowData),
          }}
        >
          {rowData.equip_visitant.toUpperCase()}
        </div>
      ),
    },
  ];

  const columns = admin
    ? adminColumns.concat(commonColumns)
    : [...commonColumns];

  const edit = {
    onRowAdd: (newRow: ITableGame) =>
      new Promise<void>((resolve) => {
        void (async () => {
          try {
            const fase = newRow.fase === undefined ? '' : newRow.fase;
            const data =
              newRow.data === undefined ||
              newRow.data == null ||
              newRow.data.trim() === ''
                ? null
                : newRow.data;
            const new_obj = { ...newRow, fase, data };
            const formattedData = getServerData(new_obj);
            const response = await createAsync({
              game: formattedData,
              sportType,
            });
            if (response !== undefined) {
              void queryClient.invalidateQueries('results');
              resolve();
            }
          } catch (err) {
            if (err instanceof Error) alert(err.message);
            resolve();
          }
        })();
      }),
    onRowDelete: (selectedRow: ITableGame) =>
      new Promise<void>((resolve) => {
        void (async () => {
          try {
            const response = await deleteAsync({
              id: selectedRow._id,
              sportType,
            });
            if (response !== undefined) {
              void queryClient.invalidateQueries('results');
              resolve();
            }
          } catch (err) {
            if (err instanceof Error) alert(err.message);
            resolve();
          }
        })();
      }),
    onRowUpdate: (updatedRow: ITableGame) =>
      new Promise<void>((resolve) => {
        void (async () => {
          try {
            const data =
              updatedRow.data === null || updatedRow.data.trim() === ''
                ? null
                : updatedRow.data;
            const new_obj = { ...updatedRow, data };
            const formattedData = getServerData(new_obj);
            const response = await updateAsync({
              game: formattedData,
              sportType,
            });
            if (response !== undefined) {
              void queryClient.invalidateQueries('results');
              resolve();
            }
          } catch (err) {
            if (err instanceof Error) alert(err.message);
            resolve();
          }
        })();
      }),
  };

  return (
    <MaterialTable
      columns={columns}
      data={data}
      title=''
      icons={tableIcons}
      editable={admin ? edit : {}}
      options={{
        headerStyle: {
          fontWeight: 'bold',
        },
        rowStyle: (data, index) => ({
          background: data && index % 2 === 0 ? '#f5f5f5' : '#fff',
        }),
        padding: 'dense',
        tableLayout: 'auto',
        toolbar: true,
        toolbarButtonAlignment: 'left',
        sorting: true,
        search: true,
        searchFieldAlignment: 'right',
        searchFieldVariant: 'standard',
        filtering: true,
        searchAutoFocus: true,
        paging: true,
        pageSizeOptions: [5, 10, 20, 50, 100],
        pageSize: 50,
        paginationType: 'stepped',
        emptyRowsWhenPaging: false,
        showFirstLastPageButtons: true,
        paginationPosition: 'bottom',
        exportButton: true,
        exportFileName: 'TableData',
        exportAllData: true,
        addRowPosition: 'first',
        actionsColumnIndex: -1,
        //selection: true,
        grouping: false,
        columnsButton: true,
      }}
    />
  );
};
