import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import Fallback from './components/Error/Fallback';
import GlobalStyle from './globalStyle';
import { SearchPage } from './pages/Search';

const App: React.FC = () => {
  const theme = createTheme({
    typography: {
      fontFamily: ['Fira Sans Condensed', 'sans-serif'].join(','),
    },
    components: {
      MuiRadio: {
        defaultProps: {
          color: 'error',
        },
      },
    },
  });

  const errorHandler = (
    error: Error,
    info: { componentStack: string }
  ): void => {
    console.log('Logging', info);
  };
  return (
    <>
      <GlobalStyle />
      <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
        <ThemeProvider theme={theme}>
          <SearchPage />
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
