import React from 'react';
import { Button } from '@mui/material';

const style = {
  '&.MuiButton-root': {
    width: 200,
    background: 'transparent',
    border: '1px solid',
    borderRadius: 25,
    borderColor: '#a9a9a9c0',
    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.075)',
    '&:hover': {
      border: '2px solid',
      borderColor: '#a5a5a5c0',
    },
  },

  '&.MuiButton-outlined': {
    color: '#727272c0',
    '&:hover': {
      fontWeight: 'bold',
    },
  },
};

interface CustomButtonProps {
  name: string;
  text: string;
}

export const CustomButton: React.FC<CustomButtonProps> = ({ name, text }) => {
  return (
    <Button
      name={name}
      type='submit'
      variant='outlined'
      size='medium'
      sx={{ ...style }}
    >
      {text}
    </Button>
  );
};
