import { ISelectObj } from 'src/lib/interfaces';
import { teamsMap } from './teamsMap';
import teamsShieldMap from './teamsShieldMap';

export const isTeamMataro = (team: string): boolean => {
  return teamsMap.has(team);
};

export const isSameTeam = (fullName: string, original: string): boolean => {
  let isTrue = false;
  if (teamsMap.has(original)) {
    teamsMap.forEach((value, key) => {
      if (fullName === value) {
        if (key === original) {
          isTrue = true;
          return;
        }
      }
    });
  }
  return isTrue;
};

export const getFullName = (team: string): string => {
  const new_team = isTeamMataro(team) ? (teamsMap.get(team) as string) : team;
  return new_team;
};

export const getAcronym = (team: string): string | undefined => {
  const result = [...teamsMap.keys()].includes(team)
    ? getFullName(team)
    : undefined;
  return result;
};

export const getTeamLabelandValue = (teams: string[]): ISelectObj[] => {
  const teams_name_corrected = teams.map((team) => {
    const newName = getFullName(team);
    return {
      label: newName,
      value: team,
      icon: teamsShieldMap.get(team) as unknown,
    };
  });
  return teams_name_corrected;
};

export const getTeamServerName = (team: string): string => {
  const new_team = teamsMap.has(team)
    ? team
    : getAcronym(team) !== undefined
    ? (getAcronym(team) as string)
    : team;
  return new_team;
};
