/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react';
import { FormLabel } from '@mui/material';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useData, useDataUpdate } from 'src/contexts/AdminContext';
import { CustomSelect } from 'src/components/shared/Select';
import { SubForm } from 'src/components/SubForm/SubForm';
import { IFormQuery, SportType, IOptions } from 'src/lib/interfaces';
import { placeholderToUndefined } from 'src/utils/dataUtils';
import logo from 'src/assets/img/iluro_logo.png';
import futbol from 'src/assets/img/icons_esport/futbol.png';
import basquet from 'src/assets/img/icons_esport/basquet.png';
import handbol from 'src/assets/img/icons_esport/handbol.png';
import waterpolo from 'src/assets/img/icons_esport/waterpolo.png';
import hoquei from 'src/assets/img/icons_esport/hoquei.png';
import hoqueiherba from 'src/assets/img/icons_esport/hoqueiherba.png';
import tennistaula from 'src/assets/img/icons_esport/tennistaula.png';
import futbolsala from 'src/assets/img/icons_esport/futbolsala.png';

import {
  Wrapper,
  Header,
  Logo,
  TitleSelectWrapper,
  Title,
  EsportSelectWrapper,
  AddWrapper,
} from './Form.styles';

const options: IOptions[] = [
  { label: 'Bàsquet Femení', value: 'basquet_fem', icon: basquet },
  { label: 'Bàsquet Masculí', value: 'basquet_mas', icon: basquet },
  { label: 'Handbol Femení', value: 'handbol_fem', icon: handbol },
  { label: 'Handbol Masculí', value: 'handbol_mas', icon: handbol },
  { label: 'Hoquei Femení', value: 'hoquei_fem', icon: hoquei },
  { label: 'Hoquei Masculí', value: 'hoquei_mas', icon: hoquei },
  {
    label: 'Hoquei Herba Masculí',
    value: 'hoqueiHerba_mas',
    icon: hoqueiherba,
  },
  { label: 'Futbol Femení', value: 'futbol_fem', icon: futbol },
  { label: 'Futbol Masculí', value: 'futbol_mas', icon: futbol },
  { label: 'Futbol Sala Femení', value: 'futbolSala_fem', icon: futbolsala },
  { label: 'Futbol Sala Masculí', value: 'futbolSala_mas', icon: futbolsala },
  {
    label: 'Tennis Taula Femení',
    value: 'tennisTaula_fem',
    icon: tennistaula,
  },
  {
    label: 'Tennis Taula Masculí',
    value: 'tennisTaula_mas',
    icon: tennistaula,
  },
  { label: 'Waterpolo Femení', value: 'waterpolo_fem', icon: waterpolo },
  { label: 'Waterpolo Masculí', value: 'waterpolo_mas', icon: waterpolo },
];

export const Form: React.FC = () => {
  const [fieldComplete, setFieldComplete] = useState(false);
  const { admin } = useData();

  const {
    setQueryParams,
    setShowResults,
    setSportType,
    setShowSecondSeason,
    setShowAdminModal,
    setAdmin,
  } = useDataUpdate();

  const methods = useForm<IFormQuery>({
    defaultValues: {
      temporada: 'placeholder',
      segona_temporada: 'placeholder',
      equip_rival: 'placeholder',
      equip_mataro: 'placeholder',
      categoria: 'placeholder',
    },
  });

  const formSubmitHandler: SubmitHandler<IFormQuery> = (data) => {
    placeholderToUndefined(data);
    setQueryParams(data);
    setShowResults(true);
  };

  // console.log(methods.watch('categoria'));
  const handleField = (value: number | SportType): void => {
    methods.reset();
    setSportType(value as SportType);
    setFieldComplete(true);
    setShowSecondSeason(false);
    setShowResults(false);
    setQueryParams({});
  };

  const handleAdminButton = () => {
    if (admin) {
      setAdmin(false);
    } else {
      setShowAdminModal(true);
    }
  };
  return (
    <FormProvider {...methods}>
      <Wrapper onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <Header>
          <FormLabel component='legend' />
          <Logo>
            <a
              href={'http://www.ilurosport.cat'}
              target='_blank'
              rel='noreferrer'
            >
              <img src={logo} alt='logo de iluro sport' />
            </a>
          </Logo>
          <TitleSelectWrapper>
            <Title data-testid='titleId'>
              <p>
                CERCADOR DE RESULTATS
                <br />
                ESPORTIUS DE MATARÓ
              </p>
            </Title>
            <EsportSelectWrapper>
              <CustomSelect
                wrapperWidth='100%'
                label='Esport'
                name='esport'
                options={options}
                handleField={handleField}
                firstValue={false}
                placeholder='Tria un esport'
              />
            </EsportSelectWrapper>
          </TitleSelectWrapper>
          <AddWrapper>
            {admin && <span data-testid='adminSpan'>Administrador</span>}
            <button
              type='button'
              data-testid='adminButton'
              onClick={handleAdminButton}
            >
              {admin ? (
                <LockOpenIcon color='action' fontSize='small' />
              ) : (
                <LockIcon color='action' fontSize='small' />
              )}
            </button>
          </AddWrapper>
        </Header>
        {fieldComplete && <SubForm />}
      </Wrapper>
    </FormProvider>
  );
};
