import AEBoet from 'src/assets/img/icons_escuts/AEBoet.png';
import BMM from 'src/assets/img/icons_escuts/BMM.png';
import CBM from 'src/assets/img/icons_escuts/CBM.png';
import CDV from 'src/assets/img/icons_escuts/CDV.png';
import CEM from 'src/assets/img/icons_escuts/CEM.png';
import CFBM from 'src/assets/img/icons_escuts/CFBM.png';
import CHM from 'src/assets/img/icons_escuts/CHM.png';
import CIR from 'src/assets/img/icons_escuts/CIR.png';
import CNM from 'src/assets/img/icons_escuts/CNM.png';
import ECM from 'src/assets/img/icons_escuts/ECM.png';
import ESP from 'src/assets/img/icons_escuts/ESP.png';
import FSM from 'src/assets/img/icons_escuts/FSM.png';
import FSM_T from 'src/assets/img/icons_escuts/FSM_T.png';
import IHC from 'src/assets/img/icons_escuts/IHC.png';
import ISC from 'src/assets/img/icons_escuts/ISC.png';
import JHM from 'src/assets/img/icons_escuts/JHM.png';
import MAEB3v from 'src/assets/img/icons_escuts/MAEB3v.png';
import MOL from 'src/assets/img/icons_escuts/MOL.png';
import PENX from 'src/assets/img/icons_escuts/PENX.png';
import UDC from 'src/assets/img/icons_escuts/UDC.png';
import UDM from 'src/assets/img/icons_escuts/UDM.png';
import UEM from 'src/assets/img/icons_escuts/UEM.png';

const teamsShieldMap = new Map();
teamsShieldMap.set('AEBoet', AEBoet);
teamsShieldMap.set('AEB', AEBoet);
teamsShieldMap.set('AECC', ESP);
teamsShieldMap.set('BMM', BMM);
teamsShieldMap.set('CBM', CBM);
teamsShieldMap.set('CDV', CDV);
teamsShieldMap.set('CEM', CEM);
teamsShieldMap.set('CFBM', CFBM);
teamsShieldMap.set('CHM', CHM);
teamsShieldMap.set('CIR', CIR);
teamsShieldMap.set('CNM', CNM);
teamsShieldMap.set('ECM', ECM);
teamsShieldMap.set('FSM Tiritas', FSM_T);
teamsShieldMap.set('Futsal Mataró', FSM);
teamsShieldMap.set('IHC', IHC);
teamsShieldMap.set('ISC', ISC);
teamsShieldMap.set('JHM', JHM);
teamsShieldMap.set('MBM3V', MAEB3v);
teamsShieldMap.set('MOL', MOL);
teamsShieldMap.set('PENX', PENX);
teamsShieldMap.set('UDC', UDC);
teamsShieldMap.set('UDM', UDM);
teamsShieldMap.set('UEM', UEM);

export default teamsShieldMap;
