import axios, { AxiosInstance } from 'axios';

const http: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://localhost:5000/api',
  headers: {
    'Content-type': 'application/json',
  },
  withCredentials: true,
});

export default http;
