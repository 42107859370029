export const teamsMap = new Map();

teamsMap.set('ISC', 'Iluro Sport Club');
teamsMap.set('IHC', 'Iluro Hockey Club');
teamsMap.set('UDC', 'Unión Deportiva Cerdanyola');
teamsMap.set('CIR', 'Unión Deportiva Cirera');
teamsMap.set('CDV', 'Club Deportivo Verdiblanca');
teamsMap.set('UDM', 'Unión Deportiva Mataronesa');
teamsMap.set('PENX', 'Penya X');
teamsMap.set('CEM', 'Club Esportiu Mataró');
teamsMap.set('ESP', 'Associació Esportiva Mataró'); // no
teamsMap.set('CFBM', 'Club Femení Bàsquet Mataró');
teamsMap.set('CBM', 'Club Bàsquet Mataró');
teamsMap.set('UEM', 'Unió Esportiva Mataró');
teamsMap.set('AEB', 'AE Boet Mataró');
teamsMap.set('AECC', 'Associació Esportiva Mataró');
teamsMap.set('MBM3V', 'Maresme Boet Mataró 3 Viles');
teamsMap.set('CHM', 'Club Hoquei Mataró');
teamsMap.set('MOL', 'Unión Deportiva Molinos');
teamsMap.set('CNM', 'Centre Natació Mataró');
teamsMap.set('BMM', 'Balonmano Mataró');
teamsMap.set('ECM', 'Esport Ciclista Mataró');
teamsMap.set('JHM', 'Joventut Handbol Mataró');
teamsMap.set('Futsal Mataró', 'Futsal Mataró');
teamsMap.set('FSM Tiritas', 'FS Mataró Tiritas');
