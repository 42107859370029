import React from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import { ISelectObj } from 'src/lib/interfaces';

const Title = styled.div`
  width: 100%;
  margin-bottom: 15px;
  span {
    color: #727272bc;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

type FieldType =
  | 'categoria'
  | 'temporada'
  | 'esport'
  | 'equip'
  | 'equip_mataro';

interface CustomRadioGroupProps {
  label: string;
  name: FieldType;
  options: ISelectObj[];
}

export const CustomRadioGroup: React.FC<CustomRadioGroupProps> = ({
  label,
  name,
  options,
}) => {
  const { control } = useFormContext();
  const opt = options.length === 1;
  return (
    <>
      <Title>
        <span>{label}</span>
      </Title>
      <FormControl size={'small'}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <RadioGroup {...field} row={false}>
              {options.length > 1 && (
                <FormControlLabel
                  value='placeholder'
                  control={<Radio size='small' />}
                  label='Tots'
                />
              )}
              {options.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={opt ? 'placeholder' : item.value}
                  control={<Radio size='small' />}
                  label={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                      }}
                    >
                      <img
                        src={item.icon as string}
                        width='30'
                        height='auto'
                        alt='icona del escut'
                      />

                      {item.label}
                    </div>
                  }
                />
              ))}
            </RadioGroup>
          )}
        />
      </FormControl>
    </>
  );
};
