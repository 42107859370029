import styled from 'styled-components';
import { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const InputsWrapper = styled.div`
  margin-top: 30px;
  padding: 30px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 30% 35% 35%;
  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
  }
`;

export const SeasonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
`;

export const ColumnWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  @media screen and (max-width: 1000px) {
    max-width: 500px;
    min-width: 300px;
    align-items: center;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const switchRoot = styled.span`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 12px;
  //margin: 10px;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: #e5e5e5;
    border-radius: 5px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 18px;
    height: 18px;
    top: -3px;
    left: 0px;
    border-radius: 18px;
    background-color: #ccc;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    //background-color: rgba(255, 255, 255, 1);
    background-color: #a9a9a9;
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 25px;
      top: -3px;
      background-color: #a9a9a9;
    }

    .${switchUnstyledClasses.track} {
      background-color: #ddd;
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;
