import React, { useState, useEffect } from 'react';
import { FormControlLabel } from '@mui/material';
import SwitchUnstyled from '@mui/base/SwitchUnstyled';
import ClipLoader from 'react-spinners/ClipLoader';
import { useQuery, UseQueryResult } from 'react-query';
import { getLabelandValue } from 'src/utils/dataUtils';
import { ISearchData, SportType } from 'src/lib/interfaces';
import { getTeamLabelandValue } from 'src/utils/teamUtils';
import { useData, useDataUpdate } from 'src/contexts/AdminContext';
import { getSearchData } from 'src/api/http-common';
import {
  Wrapper,
  InputsWrapper,
  SeasonsWrapper,
  ColumnWrapper,
  ButtonWrapper,
} from './SubFormStyles';
import { CustomButton as Button } from '../shared/Button';
import { switchRoot as Switch } from '../shared/Switch';
import { CustomRadioGroup as RadioGroup } from '../shared/Radio';
import { CustomSelect as Select } from '../shared/Select';
import InfoText from '../shared/InfoText';

export const SubForm: React.FC = () => {
  const { sportType, showSecondSeason } = useData();
  const { setShowSecondSeason } = useDataUpdate();
  const [firstSeason, setFirstSeason] = useState<number>();
  const [secondSeasonsArray, setSecondSeasonsArray] = useState<
    number[] | undefined
  >([]);

  const getSecondSeasons = (
    initialSeason: number | undefined,
    seasons: number[] | undefined
  ) => {
    const index = initialSeason ? seasons?.indexOf(initialSeason) : 0;
    setSecondSeasonsArray(seasons?.slice(index));
  };

  const handleFirstSeason = (value: number | SportType): void => {
    setFirstSeason(value as number);
  };

  const {
    isLoading,
    isError,
    isSuccess,
    error,
    data,
  }: UseQueryResult<ISearchData, Error> = useQuery<ISearchData, Error>(
    [sportType, sportType],
    () => getSearchData(sportType)
  );

  useEffect(() => {
    getSecondSeasons(firstSeason, data?.data.temporades);
  }, [firstSeason]);

  const handleChange = (show: boolean) => {
    setShowSecondSeason(show);
  };

  return (
    <>
      {isLoading && <ClipLoader />}
      {isError && <h1>{error?.message}</h1>}
      {isSuccess && data && (
        <Wrapper>
          <InputsWrapper>
            <div>
              <RadioGroup
                label='Equip de Mataró'
                name='equip_mataro'
                options={getTeamLabelandValue(data.data.equips_mataro)}
              />
            </div>
            <ColumnWrapper>
              <SeasonsWrapper>
                <Select
                  wrapperWidth='57%'
                  label={showSecondSeason ? 'Temporada Inicial' : 'Temporada'}
                  name='temporada'
                  options={getLabelandValue(data.data.temporades)}
                  handleField={handleFirstSeason}
                  placeholder='Tria una temporada'
                  firstValue={true}
                />
                <FormControlLabel
                  labelPlacement='end'
                  label=''
                  control={
                    <SwitchUnstyled
                      component={Switch}
                      checked={showSecondSeason ? true : false}
                      onChange={(e) => {
                        handleChange(e.target.checked);
                      }}
                    />
                  }
                />
              </SeasonsWrapper>
              {showSecondSeason && (
                <Select
                  wrapperWidth='57%'
                  label='Temporada Final'
                  name='segona_temporada'
                  options={
                    secondSeasonsArray !== undefined
                      ? getLabelandValue(secondSeasonsArray)
                      : getLabelandValue(data.data.temporades)
                  }
                  placeholder='Tria una segona temporada'
                  firstValue={true}
                />
              )}
              {!showSecondSeason && (
                <InfoText
                  wrapperWidth='57%'
                  text='Clica el botó adjunt al selector Temporada per escollir un interval de temporades'
                />
              )}
            </ColumnWrapper>
            <ColumnWrapper>
              <Select
                wrapperWidth='57%'
                label='Competició'
                name='categoria'
                options={getLabelandValue(data.data.categories)}
                placeholder='Tria una competició'
                firstValue={true}
              />
              <Select
                wrapperWidth='57%'
                label='Equip Rival'
                name='equip_rival'
                options={getLabelandValue(data.data.equips_rivals)}
                placeholder='Tria un equip'
                firstValue={true}
              />
            </ColumnWrapper>
          </InputsWrapper>
          <ButtonWrapper>
            <Button name='search button' text='CERCA' />
          </ButtonWrapper>
        </Wrapper>
      )}
    </>
  );
};
