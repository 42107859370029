import React from 'react';
import styled from 'styled-components';

interface InfoBoxProps {
  width?: string;
}

const InfoBox = styled.div<InfoBoxProps>`
  width: ${({ width }) => width || '57%'};
  background: transparent;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.075);
  border-color: #a9a9a9c0;
  p {
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    color: grey;
    font-size: 12px;
    text-align: justify;
  }
`;

interface InfoTextProps {
  text: string;
  wrapperWidth?: string;
}

const InfoText: React.FC<InfoTextProps> = ({ wrapperWidth, text }) => {
  return (
    <InfoBox width={wrapperWidth}>
      <p>{text}</p>
    </InfoBox>
  );
};

export default InfoText;
