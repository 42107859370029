import React from 'react';
import styled from 'styled-components';
import { FallbackProps } from 'react-error-boundary';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  role: alert;
  pre {
    font-size: 20px;
  }
  button {
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
  }
`;

const Fallback: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
  return (
    <Wrapper>
      <pre style={{ color: 'red' }}>We are sorry, something went wrong</pre>
      <button type='button' onClick={resetErrorBoundary}>
        Try Again
      </button>
    </Wrapper>
  );
};

export default Fallback;
