import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: 0;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 16px;
  }

html {
  --color-green: #01bf71;
  --size-big: 20px;
}

`;

export default GlobalStyle;
